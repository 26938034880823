<template>
  <svg
    width="7"
    height="8"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M.508 4.469a.662.662 0 0 0-.004.939L2.219 7.14c.377.38.879.59 1.413.59.533 0 1.034-.21 1.41-.588l1.713-1.697a.664.664 0 1 0-.934-.943l-1.525 1.51V.657a.664.664 0 1 0-1.328 0v5.351l-1.52-1.535a.664.664 0 0 0-.94-.004z"
      :fill="color"
      fill-rule="nonzero"
    />
  </svg>
</template>

<script lang="ts">
const colors: Record<string, string> = {
  dark: '#121212',
  light: '#ABABAB',
  transparent: 'transparent',
  default: '#121212',
}

export default defineNuxtComponent({
  props: {
    variant: {
      type: String,
      validator: (value: string): boolean => Object.keys(colors).includes(value),
      default: 'default',
    },
    size: {
      type: String,
      validator: (prop: string) => ['sm', 'md'].includes(prop),
      default: 'md',
    },
  },
  setup(props) {
    const color = computed(() => colors[props.variant])
    return { 
      color,
    }
  },
})
</script>
